import React from "react";
import { graphql } from "gatsby";

import DefaultLayout from "../layouts/default";
import HeaderTitle from "../components/HeaderTitle";
import GreenLead from "../components/GreenLead";
import CenteredTextBlock from "../components/CenteredTextBlock";

interface TextSeite {
  data: {
    cms: {
      entry: {
        title: string;
        greenLead: string;
        normalText: string;
      };
    };
  };
}

export const TextSeiteQuery = graphql`
  query ($uri: String!, $siteId: CMS_QueryArgument) {
    cms {
      entry(uri: [$uri], siteId: [$siteId]) {
        title
        siteId
        # localized {
        #   uri
        #   siteId
        # }
        ... on CMS_main_textSeite_Entry {
          metaBeschreibung
          greenLead
          normalText
        }
      }
    }
  }
`;

const TextSeite = ({ data }) => (
  <DefaultLayout
    siteId={data.cms.entry.siteId}
    title={data.cms.entry.title}
    //links={data.cms.entry.localized}
    beschreibung={
      data.cms.entry.metaBeschreibung ? data.cms.entry.metaBeschreibung : false
    }
  >
    <HeaderTitle inhalt={data.cms.entry.title} />
    <GreenLead inhalt={data.cms.entry.greenLead} />
    <CenteredTextBlock inhalt={data.cms.entry.normalText} />
  </DefaultLayout>
);

export default TextSeite;
